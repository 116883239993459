.body {
  font-family: Arial !important;
}

.terms {
  padding: 30px;
  header {
    margin-bottom: 50px;
  }
  h5 {
    margin-top: 10px;
  }
  h1, h3, ol, p {
    color: #1b1b1b;
  }
  h1 {
    font-size: 26px;
  }
  &-block {
    margin-top: 36px;
    line-height: 2.14;
    h3 {
      font-size: 18px;
      margin-bottom: 18px;
    }
    p, ol {
      margin-top: 16px;
    }
  }
  ol {
    padding-left: 36px;
  }
}