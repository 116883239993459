* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin-left: calc(100vw - 100%);
  height: 100%;
  font-family: Open Sans,Helvetica,Arial,sans-serif;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
  overflow: auto;
}

li {
  list-style: none;
}

.slick-slide:nth-of-type(4n+0) .makeStyles-marginRight-307 {
  margin-right: 0;
}